<template>
    <div>        
    <section class='trbaner'>
        <div class="foxj">
            <div class='am-container'>
                <div class='txt'>
                    <div class="am-vertical-align-middle">
                     <p class="tit">动力环境监控解决方案服务商</p>
                     <p class="note">致力于为客户提供专业的动力环境监控解决方案，实现应用场景的赋能。</p>
                </div>
                </div>
            </div>
        </div>
        <img src='../../src/assets/images/解决方案.png'>
    </section>
    <section class="am-path">
        <div class="am-container">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/solution' }">解决方案</el-breadcrumb-item>
            <el-breadcrumb-item >综合监控主机</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
    </section>
    <section class="nybox">
        <div class="am-container">
            <div class="nyleft">
                <el-col>
                <p class="tit">解决方案</p>
                 <el-menu
                 default-active="2"
                 class="el-menu-vertical-demo">
            <el-menu-item v-for='(item,index) in dataList' :key='index' ><a @click="func(item)">{{item.solution_name}}</a></el-menu-item>
          <!-- <el-menu-item index="1-2">智能报警模块</el-menu-item> -->   
    </el-menu>
  </el-col> 
            </div>
            <div class="nyright">
                <div class="nyproxq">
                    <div class="nyprohead">
                        <h1>{{this.Data.solution_name}}</h1>
                    </div>
                    <div class="xiangxi02">
                        <p v-html="this.Data.solution_details"></p>
                        <!-- <div class="hd2">产品详情</div>
                        <div class="xiangxi03">
                            <p>RZY-8800综合监控服务器是针对数据中心、通信基站、药品库房，变电站等重要场所推出的新一代多用途一体化监控主机。</p>
                            <p>RZY-产品遵循一体化(ALL IN ONE)设计理念，搭载工业级32位高性能ARM处理器，Linux操作系统， 采用B/S架构，提供便捷的嵌入式WEB服务。内嵌监控系统集成信息采集、分析处理、策略执行、联动控制、报表统计、日志记录、智能告警等功能，无需依赖网络、服务器及上位机软件即可完成供配电、UPS、蓄电池、温湿度、空调、漏水、新风机、烟感、消防、红外防盗、视频图像等一体化综合实时监控管理。高度集成电话短信通讯模组及TTS语音合成服务，可通过电话语音、短信、邮件、现场广播、声光进行报警。被监控传感设备直接接入监控主机，无需外接采控模块和协议转换模块，且接口采用标准RJ45接口并附带供电，由监控主机集中为各种传感器提供工作电源，停电状态下能够使用内置后备电源独立为主机和传感器提供电，保证监控系统持续稳定运行。管理人员可直接通过浏览器和相应权限就可以实时查看监控场地环境、设备参数、告警、报表、日志等信息，代替人工巡检，实现无人值守，提高维护效率，降低管理成本。</p>
                            <p>RZY-一体化监控系统部署简单、维护方便、软件易用。重要的是降低了故障点、维护点，减少了外部网络、供电等不稳定因素对系统的干扰，提高监控系统的可靠性和稳定性，保障用户的生产安全。</p>
                            <p>RZY-适用于政府、电力、军队、银行、电信、教育、医院、机场、铁路、工厂、酒店、仓库、交通等行业，专为计算机网络动力环境、通信动力环境、通信基站、电力基站、变电站、居民配电所、UPS动力环境、蓄电池组动力环境、户外机柜、无人值守现场、药品库房、仓库等场景的动力、环境、安全及网络的一体化监控而设计。</p>
                        </div> -->
                    </div>
                    <!-- <div class="xiangxi02">
                        <div class="hd2">系统架构</div>
                        <div class="xiangxi03">
                        <img src="~images/d86887f2f1ea59b6.jpg" title='RZY-8800综合监控系统拓扑图' alt="RZY-8800综合监控系统拓扑图">
                        </div>
                    </div>
                    <div class="xiangxi02">
                        <div class="hd2">部署示意</div>
                        <div class="xiangxi03">
                        <img src="~images/062a779d0701e1c6.jpg" title='RZY-RZY-8800综合监控系统部署示意图' alt="RZY-RZY-8800综合监控系统部署示意图">
                        </div>
                    </div>
                    <div class="xiangxi02">
                        <div class="hd2">监控功能</div>
                        <div class="xiangxi03">
                             <el-table
                            :data="tableDate"
                            border
                            style="width: 100%"
                            >
                            <el-table-column
                            fixed
                            prop="id"
                            label="序号"
                            width="100">
                            </el-table-column>
                            <el-table-column
                            prop="xtlb"
                            label="系统类别"
                            width="120">
                            </el-table-column>
                            <el-table-column
                            prop="gn"
                            label="功能"
                            width="120">
                            </el-table-column>
                            <el-table-column
                            prop="gnxq"
                            label="功能需求"
                            >
                            </el-table-column>
                        </el-table>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import {
    getList,
    getOne
} from 'api/GetList'
export default {
    data() {
        return {
            imagesbox:[
                {id:0,idView:require('../assets/images/501.jpg')},
                {id:1,idView:require('../assets/images/502.jpg')},
                {id:2,idView:require('../assets/images/503.jpg')}
            ],
            Data:[

            ],
            dataList:[],
            tableDate:[
                {
                    id:'1',
                    xtlb:'环境系统',
                    gn:'漏水监测',
                    gnxq:'对空调、加湿器、门口、窗户等附近进行围闭监测，有水漏出时可实时报警。'
                },
                {
                    id:'2',
                    xtlb:'',
                    gn:'温湿度监测',
                    gnxq:'实时监测动力环境内各采样点的温度和湿度参数。'
                },
                {
                    id:'3',
                    xtlb:'',
                    gn:'精密空调监测',
                    gnxq:'监测精密空调回风温度、送风温度、空调模式、运行状态、设定参数以及告警故障等信息。'
                },
                {
                    id:'4',
                    xtlb:'',
                    gn:'普通空调监测',
                    gnxq:'监测空调开关机状态及环境温湿度信息，支持红外远程开关机、模式选择、温度调节、风速调节等操作。'
                },
                {
                    id:'5',
                    xtlb:'',
                    gn:'新风机监测',
                    gnxq:'监测新风机开关机及运行状态。'
                },
                {
                    id:'6',
                    xtlb:'动力系统',
                    gn:'配电监测',
                    gnxq:'实时监测动力环境的供电质量，包含供电电压、电流、功率因数、频率、有功功率、无功功率、视在功率、电能等。'
                },
                {
                    id:'7',
                    xtlb:'环境系统',
                    gn:'漏水监测',
                    gnxq:'对空调、加湿器、门口、窗户等附近进行围闭监测，有水漏出时可实时报警。'
                },
                {
                    id:'8',
                    xtlb:'',
                    gn:'UPS监测',
                    gnxq:'监测输入输出电压、电流、功率、后备时间等参数，整流器、逆变器、电池、旁路等部件运行状态和告警信息。'
                },
                {
                    id:'9',
                    xtlb:'',
                    gn:'市电监测',
                    gnxq:'监测单相或三相四线ABC相中任一相断电状态'
                },
                {
                    id:'10',
                    xtlb:'网络系统（注：暂不支持，开发中）',
                    gn:'路由器',
                    gnxq:'TCP服务端口网络状态'
                }
            ],
        }
    },
    methods: {
    getList() {
        this.isShowloading = true;
      console.log(this.searchInfo);
      getList(this.searchInfo).then((res) => {
        console.log(res);
        this.dataList = res.data.list;
        // this.getproClass(this.dLists)
        console.log(this.dLists)
        // this.total = res.data.recordCount;
        this.isShowloading = false;
        console.log(this.modelForm)
      });
      },
    getOne() {
        let id = this.$route.query.id;
        getOne({fuid: id}).then((res) => {
            this.Data = res.data.entity
        })
    },
    func(item) {
         let id = item.fuid;
        getOne({fuid: id}).then((res) => {
            this.Data = res.data.entity
        })
      }
    },
    mounted() {
        this.getList(),
        this.getOne()
    }
}
</script>
<style lang="scss" scoped>
.trbaner {
    position: relative;
}
.trbaner img {
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
}
.trbaner .foxj {
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}
.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}
.foxj .txt {
    position: absolute;
    text-align: left;
    left: 1.5rem;
    height: 100%;
    display: table-cell;
    width: 46%;
}
.foxj .txt .tit{
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}
.foxj .note{
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}
.am-container::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.foxj .txt .am-vertical-align-middle {
       position: relative;
       top: 30%;
}
.am-path {
    height: 50px;
    .el-breadcrumb {
    border: 1px solid #eee;
    font-size: 14px;
    line-height: 50px;
    height: 50px;
    padding-left: 20px;
}
}
.nybox {
    padding: 30px 0;
    background-color: #f5f5f5;
    .nyleft {
        float: left;
        width: 254px;
        
        .tit{
            font-size: 22px;
            padding: 15px 0 15px 25px;
            background: #00579d;
            color: #fff;
        }
        .el-submenu {
            background-color: #f5f5f5;
            margin: 6px 10px;
        }
    }
    .nyright {
        width: 77%;
        float: right;
        background-color: #fff;
        .nyproxq {
            height: 100%;
        }
        .nyproxq::after{
               content: ".";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
                -webkit-box-sizing: border-box;
                 box-sizing: border-box; 
        }        
        .nyprohead::after{
               content: ".";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
                -webkit-box-sizing: border-box;
                 box-sizing: border-box; 
        }
        .nyprohead {
            padding: 30px;
            display: block;
            height: 100%;
            h1{
                font-size:22px ;
                font-weight: normal;
            }
        }
        .xiangxi02 {
            clear: both;
            overflow: hidden;
            padding: 0 30px 30px 30px;
            text-align: left;
            .hd2 {
                background: url('~images/icon_po.png') 15px center no-repeat #f5f5f5;
                    margin-bottom: 25px;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 60px;
                    padding-left: 50px;
            }
            .xiangxi03 {
                    color: #333;
                    line-height: 2;
                    overflow: hidden;
                }

        }
        .xgcp {
                padding: 15px 0;
                margin: 0 30px;
                font-size: 22px;
                border-bottom: 1px solid #eee;
                text-align: left;
        }
        .xgproli {
            padding: 15px;
                 ul {
                    display: -webkit-flex;
                    display: flex;
                    align-content: space-around;
                    -webkit-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -webkit-align-content: center;
                    align-content: center;
                    justify-content: space-between;
                    li {
                    padding:0 10px ;
                    list-style: none;
                    width: 30%;
                    a{
                        display: block;
                        background: #fff;
                        text-align: center;
                        border: 1px solid #fff;
                        overflow: hidden;
                        i {
                            overflow: hidden;
                        }
                        img {
                            width: 100%;
                            transition: all 0.7s;
                        }
                        img:hover {
                            transform: scale(1.2) ;
                        }
                        .txt{
                            padding:20px;
                        .tit {
                                font-size: 16px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-bottom: 4px;
                                color: #333;
                        }
                        .note {
                                font-size: 12px;
                                margin-bottom: 6px;
                                color: #999;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                        }}
                    }
                    a:hover {
                        border: 1px solid #0e90d2
                    }
                }
                        }
                }
    }
}
.homed {
    text-align: center;
    font-size: 24px;
    color: #fff;
    padding: 3% 0;
    background: url('~images/adbg.jpg') center no-repeat;
    background-size: cover;
}
</style>