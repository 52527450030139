import axios from 'axios';
let baseUrl = '/zhcwaterweb';
var qs = require('qs');  

//添加解决方案
export const addProduct = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/solution/add`,pm);
}
//修改解决方案
export const editProduct = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/solution/update`,pm);
}
//删除解决方案
export const deteleProduct = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/solution/delete`,pm);
}
//获取解决方案
export const getList = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/solution/getList`,pm);
}
//查询解决方案
export const getOne = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/solution/getOne`,pm);
}